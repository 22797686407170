import {
  ChannelSuggestion,
  GetMeAPI,
  GetMemberGroupsResponse,
  GetPlatformGroupsResponse,
} from "@astronautchat/common-backend";
import {
  CommunityEntity,
  CommunityMemberGroupEntity,
  ConversationEntity,
  CustomerEntity,
  CustomerUserEntity,
  DiscordRoleEntity,
  PlatformDiscordEntity,
  PlatformEntity,
  PlatformEventEntity,
  PlatformGroupEntity,
  PlatformRedditEntity,
  PlatformSlackEntity,
  PlatformTelegramEntity,
  TelegramBaseMemberEntity,
  UserEntity,
} from "@astronautchat/schema";
import { DateRangeType } from "@components/DateRangeCalendar";
import { DailyCall } from "@daily-co/daily-js";
import { ChatPromptFormType } from "@schemas/chatPromptForm";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { atom } from "recoil";
import { localStorageEffect } from "./localStorageEffect";
import { sessionStorageEffect } from "./sessionStorageEffect";

export type AuthStatus = "loading" | "authorized" | "unauthorized";

export const shouldHideNavbarState = atom({
  key: "shouldHideNavbarState",
  default: false as boolean,
});

export const userState = atom({
  key: "userState",
  default: undefined as UserEntity | undefined,
  effects: [localStorageEffect("userState")],
});

export const credentialsState = atom({
  key: "credentials",
  default: null as null | string,
});

export const customerUserState = atom({
  key: "customerUserState",
  default: undefined as
    | Pick<CustomerUserEntity, "customerUserId" | "customerId" | "role" | "userId">
    | undefined,
  effects: [localStorageEffect("customerUserState")],
});

export const customerState = atom({
  key: "customerState",
  default: undefined as Omit<CustomerEntity, "createdAt" | "deletedAt" | "updatedAt"> | undefined,
  effects: [localStorageEffect("customerState")],
});

// Actively selected community
export const communityState = atom({
  key: "communityState",
  default: undefined as CommunityEntity | undefined,
  effects: [localStorageEffect("communityState")],
});

export type PlatformWithPossiblePlatforms = Omit<
  PlatformEntity,
  "createdAt" | "updatedAt" | "deletedAt"
> & {
  platformDiscord?: Omit<PlatformDiscordEntity, "createdAt" | "updatedAt" | "deletedAt"> | null;
  platformSlack?: Omit<PlatformSlackEntity, "createdAt" | "updatedAt" | "deletedAt"> | null;
  platformTelegram?: Omit<PlatformTelegramEntity, "createdAt" | "updatedAt" | "deletedAt"> | null;
  platformReddit?: Omit<PlatformRedditEntity, "createdAt" | "updatedAt" | "deletedAt"> | null;
};

export const platformListState = atom({
  key: "platformListState",
  default: [] as PlatformWithPossiblePlatforms[],
});

export const adminState = atom({
  key: "adminState",
  default: undefined as UserEntity["userId"] | undefined,
  effects: [localStorageEffect("adminState")],
});

// Active platform for the user
export const activePlatformIdState = atom({
  key: "activePlatformIdState",
  default: undefined as PlatformEntity["platformId"] | undefined | null,
  effects: [localStorageEffect("activePlatformIdState")],
});

// The communities that this user is a part of
export const communitiesState = atom({
  key: "communitiesState",
  default: [] as CommunityEntity[],
  effects: [localStorageEffect("communitiesState")],
});

export const channelsState = atom({
  key: "channelsState",
  default: [] as ChannelSuggestion[],
  effects: [localStorageEffect("channelsState")],
});

export const groupsState = atom({
  key: "groupsState",
  default: [] as {
    discordRoleId: DiscordRoleEntity["discordRoleId"];
    name: DiscordRoleEntity["name"];
    platformId: PlatformEventEntity["platformId"];
  }[],
  effects: [localStorageEffect("groupsState")],
});

// Used for the navigation between the prompt homepage and the conversation screen
export const storedMentionsState = atom({
  key: "storedMentionsState",
  default: [] as ChatPromptFormType["mentions"],
  effects: [localStorageEffect("storedMentionsState")],
});

export const startDateState = atom({
  key: "startDateState",
  default: undefined as Date | undefined,
  effects: [localStorageEffect("startDateState")],
});

export const endDateState = atom({
  key: "endDateState",
  default: undefined as Date | undefined,
  effects: [localStorageEffect("endDateState")],
});

export const conversationsState = atom({
  key: "conversationsState",
  default: [] as ConversationEntity[],
  effects: [localStorageEffect("conversationsState")],
});

export const tagsState = atom({
  key: "tagsState",
  default: [] as GetMeAPI["tags"],
});

export const moderationRolesState = atom({
  key: "moderationRolesState",
  default: [] as DiscordRoleEntity["discordRoleId"][],
  effects: [localStorageEffect("moderationRolesState")],
});

// Used to store the active onboarding platform
export const onboardingPlatformState = atom({
  key: "onboardingPlatformState",
  default: undefined as
    | PlatformDiscordEntity
    | PlatformSlackEntity
    | PlatformTelegramEntity
    | undefined,
  effects: [localStorageEffect("onboardingPlatformState")],
});

// Used to store the telegram user id
export const onboardingTelegramUserIdState = atom({
  key: "onboardingTelegramUserIdState",
  default: undefined as TelegramBaseMemberEntity["userId"] | undefined,
  effects: [localStorageEffect("onboardingTelegramUserIdState")],
});

// Used to store the session token
export const sessState = atom({
  key: "_sess",
  default: undefined as string | undefined,
  effects: [localStorageEffect("_sess")],
});

// used to flag the current session as a demo
export const demoState = atom({
  key: "isInDemo",
  default: false as boolean,
  effects: [localStorageEffect("isInDemo")],
});

export const dateRangeState = atom({
  key: "dateRangeState",
  default: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())] as DateRangeType,
  effects: [localStorageEffect("dateRangeState")],
});

export const platformGroupsState = atom({
  key: "platformGroupsState",
  default: [] as GetPlatformGroupsResponse,
  effects: [localStorageEffect("platformGroupsState")],
});

export const memberGroupsState = atom({
  key: "memberGroupsState",
  default: [] as GetMemberGroupsResponse,
  effects: [localStorageEffect("memberGroupsState")],
});

export const selectedPlatformGroupState = atom({
  key: "selectedPlatformGroupState",
  default: [] as PlatformGroupEntity["platformGroupId"][],
  effects: [localStorageEffect("selectedPlatformGroupState")],
});

export const selectedMemberGroupState = atom({
  key: "selectedMemberGroupState",
  default: [] as CommunityMemberGroupEntity["communityMemberGroupId"][],
  effects: [localStorageEffect("selectedMemberGroupState")],
});

export const excludedMemberGroupState = atom({
  key: "excludedMemberGroupState",
  default: [] as CommunityMemberGroupEntity["communityMemberGroupId"][],
  effects: [localStorageEffect("excludedMemberGroupState")],
});
