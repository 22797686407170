import Image from "next/image";

export const CrossIcon = ({
  width = 24,
  height = 24,
  color,
}: {
  width?: number;
  height?: number;
  color: "black" | "red" | "gray";
}) => (
  <Image
    alt="cross icon"
    src={
      color === "black"
        ? "/cross.svg"
        : color === "red"
        ? "/cross-red.svg"
        : color === "gray"
        ? "/cross-gray.svg"
        : "/cross.svg"
    }
    width={width}
    height={height}
    priority
    style={{ objectFit: "contain", width }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
