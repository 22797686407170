import { PlatformEntity } from "@astronautchat/schema";
import { DiscordIcon } from "./DiscordIcon";
import { RedditIcon } from "./RedditIcon";
import { SlackIcon } from "./SlackIcon";
import { TelegramIcon } from "./TelegramIcon";

export const SourceIcon = ({
  type,
  isActive = false,
  scheme,
}: {
  type: PlatformEntity["platformType"];
  isActive?: boolean;
  scheme: "brand" | "mono";
}) => {
  const color = scheme === "brand" ? "brand" : isActive ? "dark" : "gray";
  if (type === "discord") {
    return (
      <DiscordIcon
        color={color}
        width={19}
        height={15}
      />
    );
  } else if (type === "slack") {
    return (
      <SlackIcon
        color={color}
        width={19}
        height={15}
      />
    );
  } else if (type === "telegram") {
    return (
      <TelegramIcon
        color={color}
        width={18}
        height={14}
      />
    );
  } else if (type === "reddit") {
    return (
      <RedditIcon
        color={color}
        width={19}
        height={15}
      />
    );
  } else {
    return <></>;
  }
};
