import { adminState, credentialsState } from "@recoil/atoms";
import log from "@services/Log";
import { config } from "@utils/config";
import axios from "axios";
import { getRecoil } from "recoil-nexus";

const authApi = axios.create({
  baseURL: config.NEXT_PUBLIC_AUTH_API,
  timeout: 90000,
});

/* istanbul ignore next */
authApi.interceptors.request.use(async function (config) {
  if (config.headers === undefined) {
    // @ts-ignore
    config.headers = {};
  } else {
    const token = getRecoil(credentialsState);
    if (token) {
      config.headers.authorization = `Bearer ${token}`;

      const admin = getRecoil(adminState);
      if (admin) {
        config.headers.userid = admin;
        config.headers.astrointernal = true;
      }
    } else {
      log.warn("No JWT token was present during the request");
      // User isn't authed, so cancel the request
      throw new axios.Cancel("No token present");
    }
  }

  return config;
});

export default authApi;
