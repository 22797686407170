import Image from "next/image";

export const PackagePlusIcon = ({
  width = 24,
  height = 24,
  color,
}: {
  width?: number;
  height?: number;
  color: "black" | "white";
}) => (
  <Image
    alt="Package Plus icon"
    src={`/package-plus-${color}.svg`}
    width={width}
    height={height}
    priority
    style={{ objectFit: "contain", width }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
