import { CrossIcon } from "@assets/icons/CrossIcon";
import { Box, Button, DialogProps, Dialog as MuiDialog, Typography } from "@mui/material";
import palette from "@styles/palette";

type DialogExtendedProps = DialogProps & {
  title: string;
  onClose: () => void;
  width?: number;
};

export const Dialog = (props: DialogExtendedProps) => {
  return (
    <MuiDialog {...props}>
      <Box
        sx={{
          background: "white",
          display: "flex",
          width: props?.width,
          padding: 20,
          flexDirection: "column",
          alignItems: "flex-start",
          borderRadius: 12,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="appPageTitleSecondary">{props.title}</Typography>
          <Button
            variant="typography"
            onClick={props.onClose}
          >
            <CrossIcon
              width={24}
              height={24}
              color="black"
            />
          </Button>
        </Box>
        <Box
          sx={{
            height: 2,
            background: palette.brand.neutral.lightGray,
            width: "100%",
            marginTop: 16,
            marginBottom: 20,
          }}
        />
        {props.children}
      </Box>
    </MuiDialog>
  );
};
