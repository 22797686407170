import Image from "next/image";

export const UsersPlusIcon = ({
  width = 24,
  height = 24,
  color,
}: {
  width?: number;
  height?: number;
  color?: "black" | "white";
}) => (
  <Image
    alt="users plus icon"
    src={color === "black" ? "/users-plus-black.svg" : "/users-plus-white.svg"}
    width={width}
    height={height}
    priority
    style={{
      objectFit: "contain",
      width,
    }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
