import { ArrowWallIcon } from "@assets/icons/ArrowWallIcon";
import { BellIcon } from "@assets/icons/BellIcon";
import { ChatIcon } from "@assets/icons/ChatIcon";
import { CheckboxCircleIcon } from "@assets/icons/CheckboxCircleIcon";
import { ExpandIcon } from "@assets/icons/ExpandIcon";
import { LineChartIcon } from "@assets/icons/LineChartIcon";
import { ListIcon } from "@assets/icons/ListIcon";
import { MemberIcon } from "@assets/icons/MemberIcon";
import { MembersIcon } from "@assets/icons/MembersIcon";
import { PackagePlusIcon } from "@assets/icons/PackagePlusIcon";
import { PlusSquareIcon } from "@assets/icons/PlusSquareIcon";
import { ReportIcon } from "@assets/icons/ReportIcon";
import { ShieldZapIcon } from "@assets/icons/ShieldZapIcon";
import { SourceIcon } from "@assets/icons/SourceIcon";
import { TagIcon } from "@assets/icons/TagIcon";
import { UsersPlusIcon } from "@assets/icons/UsersPlusIcon";
import { extractLazyDistinctObjects } from "@astronautchat/common";
import { CustomerEntity, PlatformEntity } from "@astronautchat/schema";
import { Avatar } from "@components/Avatar";
import { CollapsibleList, ICON_BOX_WIDTH } from "@components/CollapsibleList";
import { Column } from "@components/common/Column";
import { Divider } from "@components/common/Divider";
import { Row } from "@components/common/Row";
import { FreeTrialBlock } from "@components/plans/FreeTrialBlock";
import { UpgradeDialog } from "@components/plans/UpgradeDialog";
import { checkHasTrialExpired } from "@lib/checkHasTrialExpired";
import { createAvatarUrl } from "@lib/createAvatarUrl";
import { findPlanByPriceId } from "@lib/findPlanByPriceId";
import { Box, Button, CircularProgress, Modal, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  activePlatformIdState,
  adminState,
  communitiesState,
  communityState,
  conversationsState,
  customerState,
  customerUserState,
  demoState,
  moderationRolesState,
  userState,
} from "@recoil/atoms";
import { collapseNavContainerState } from "@recoil/utilityAtoms";
import Amplitude from "@services/Amplitude";
import log from "@services/Log";
import User from "@services/User";
import palette from "@styles/palette";
import { NavDropdown } from "@templates/Navigation/NavDropdown";
import Image from "next/image";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { forwardRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { getRecoil, setRecoil } from "recoil-nexus";
import { NavItem } from "./NavItem";

const CONVERSATION_TAKE = 25;

const SUPPORT_ACCESS_IDS: CustomerEntity["customerId"][] = [
  "hex06m74xrellddtyle8e1ab",
  "clpmxaxv52kpg1sl5itx1z0yq",
  "cloxdfsaf02qiqr0f7cskwx6v",
];

const DIVIDER_COLOR = "#383838";

export const NavChat = () => {
  const conversations = useRecoilValue(conversationsState);

  const { ref } = useInView({
    threshold: 0.1,
    onChange: (inView) => {
      if (inView && conversations && conversations?.length >= CONVERSATION_TAKE) {
        fetchNextPage();
      }
    },
  });

  const router = useRouter();
  const isInDemo = useRecoilValue(demoState);
  const isCollapsed = useRecoilValue(collapseNavContainerState);
  const activePlatformId = useRecoilValue(activePlatformIdState);
  const community = useRecoilValue(communityState);
  const customerUser = useRecoilValue(customerUserState);
  const customer = useRecoilValue(customerState);
  const currentPlan = findPlanByPriceId(customer?.planId);
  const isTrialPeriod = customer?.subscriptionStatus === "trialing";
  const hasTrialExpired = checkHasTrialExpired({
    subscriptionStatus: customer?.subscriptionStatus,
    trialEnd: customer?.trialEnd,
  });
  const communities = useRecoilValue(communitiesState);
  const admin = useRecoilValue(adminState);
  const user = useRecoilValue(userState);
  const { enqueueSnackbar } = useSnackbar();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);

  const activeConversationId = router?.query?.conversationId;
  const isReportPage = router?.pathname === "/chat/reports";
  const isModeratorPage = router?.pathname.includes("/chat/moderation");
  const isChatPage = router?.pathname === "/chat";

  const PADDING_X_CONTAINER = isCollapsed ? 21 : 15;

  const { fetchNextPage, isFetching } = useInfiniteQuery(
    ["User.getConversations"],
    async ({ pageParam = 1 }) => {
      const data = await User.getConversations({ take: CONVERSATION_TAKE, page: pageParam });
      setRecoil(
        conversationsState,
        extractLazyDistinctObjects(conversations, data, "conversationId")
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage && lastPage?.length === 0) return undefined;
        return pages.length + 1;
      },
      onError: (error: any) => log.error(error, error?.message),
    }
  );

  const handleOnClickReports = () => {
    router.push("/chat/reports");
  };

  const handleOnClickModeration = () => {
    const moderationRoles = getRecoil(moderationRolesState);

    if (moderationRoles?.length > 0) {
      router.push("/chat/moderation");
    } else {
      router.push("/chat/moderation/roles");
    }
  };

  return (
    <Box
      sx={{
        paddingY: 30,
        height: "100%",
        maxHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div style={{ paddingRight: PADDING_X_CONTAINER, paddingLeft: PADDING_X_CONTAINER }}>
        <div
          style={{
            flex: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: isCollapsed ? 60 : 0,
              opacity: isCollapsed ? 1 : 0,
              transition: "all 0.1s ease-out",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              Amplitude.logEvent("Pressed small Astronaut logo in collapsed state");
              router.push("/chat");
            }}
          >
            <Image
              alt="Astronaut white nugget logo"
              src={"/nugget-white.svg"}
              width={35}
              height={45}
              priority
              style={{ objectFit: "contain", width: 45 }}
              sizes={`(max-width: 768px) 70px, (max-width: 1200px) 70px, 70px`}
            />
          </Box>
          <Box
            sx={{
              height: 44,
              width: isCollapsed ? 0 : "100%",
              opacity: isCollapsed ? 0 : 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ display: "flex", cursor: "pointer" }}
              onClick={() => {
                Amplitude.logEvent("Pressed Astronaut logo");
                router.push("/chat");
              }}
            >
              <Image
                width={151}
                height={44}
                alt="All white Astronaut wordmark"
                src={"/wordmark-allwhite.svg"}
                priority
                style={{ objectFit: "contain", width: 151 }}
                sizes={`(max-width: 768px) 302px, (max-width: 1200px) 302px, 302px`}
              />
            </Box>
            <Button
              sx={{
                display: "flex",
                padding: "5px !important",
                minWidth: "unset",
                justifyContent: "flex-end",
                width: 45,
                height: 45,
              }}
              onClick={() => {
                Amplitude.logEvent("Pressed button to collapse navigation");
                setRecoil(collapseNavContainerState, true);
              }}
            >
              <ArrowWallIcon
                direction="left"
                width={27}
                height={27}
                color="white"
              />
            </Button>
          </Box>
        </div>
      </div>
      <Divider color={DIVIDER_COLOR} />
      <Box
        sx={{
          gap: 10,
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          overflowX: "visible",
          flex: 1,
        }}
      >
        <NavDropdown isCollapsed={isCollapsed} />
        <Box />
        {(SUPPORT_ACCESS_IDS.includes(customer!.customerId) ||
          admin ||
          process.env.NODE_ENV === "development") && (
          <Column>
            <NavItem
              isActive={router?.pathname === "/chat/support"}
              isCollapsed={isCollapsed}
              isLocked={false}
              onClick={() => router.push("/chat/support")}
              title="Support"
              IconComponent={
                <ShieldZapIcon
                  width={24}
                  height={24}
                  color="white"
                />
              }
            />
            <Column>
              <NavItem
                isActive={router?.pathname === "/chat/platforms"}
                isCollapsed={isCollapsed}
                isLocked={false}
                isIdented
                onClick={() => router.push("/chat/platforms")}
                title="Source Groups"
                IconComponent={
                  <Row
                    width={ICON_BOX_WIDTH}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <PackagePlusIcon
                      width={20}
                      height={20}
                      color="white"
                    />
                  </Row>
                }
              />
              <NavItem
                isActive={router?.pathname === "/chat/members/groups"}
                isCollapsed={isCollapsed}
                isLocked={false}
                isIdented
                onClick={() => router.push("/chat/members/groups")}
                title="Member Groups"
                IconComponent={
                  <Row
                    width={ICON_BOX_WIDTH}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <UsersPlusIcon
                      width={20}
                      height={20}
                      color="white"
                    />
                  </Row>
                }
              />
            </Column>
          </Column>
        )}
        <NavItem
          isActive={isReportPage}
          isCollapsed={isCollapsed}
          isLocked={false}
          onClick={handleOnClickReports}
          title="Reports"
          IconComponent={
            <ReportIcon
              width={24}
              height={24}
            />
          }
        />
        <NavItem
          isActive={isModeratorPage}
          isCollapsed={isCollapsed}
          isLocked={false}
          onClick={handleOnClickModeration}
          title="Moderators"
          IconComponent={
            <LineChartIcon
              width={18}
              height={18}
            />
          }
        />
        <Column>
          <NavItem
            isActive={false}
            isCollapsed={isCollapsed}
            isLocked={false}
            onClick={() => router.push("/chat/members")}
            title="Members"
            IconComponent={
              <Row
                width={ICON_BOX_WIDTH}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <MembersIcon
                  width={20}
                  height={20}
                />
              </Row>
            }
          />
          <Column>
            <NavItem
              isActive={router?.pathname === "/chat/members"}
              isCollapsed={isCollapsed}
              isLocked={false}
              isIdented
              onClick={() => router.push("/chat/members")}
              title="All members"
              IconComponent={
                <Row
                  width={ICON_BOX_WIDTH}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <MemberIcon
                    width={20}
                    height={20}
                    color="white"
                  />
                </Row>
              }
            />
            <NavItem
              isActive={router?.pathname === "/chat/members/activity"}
              isCollapsed={isCollapsed}
              isLocked={false}
              isIdented
              onClick={() => router.push("/chat/members/activity")}
              title="Activity"
              IconComponent={
                <Row
                  width={ICON_BOX_WIDTH}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <ListIcon
                    color={"white"}
                    width={20}
                    height={20}
                  />
                </Row>
              }
            />
            <NavItem
              isActive={router?.pathname === "/chat/tag"}
              isCollapsed={isCollapsed}
              isLocked={false}
              isIdented
              onClick={() => router.push("/chat/tag")}
              title="Tags"
              IconComponent={
                <Row
                  width={ICON_BOX_WIDTH}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <TagIcon
                    color={"white"}
                    width={20}
                    height={20}
                  />
                </Row>
              }
            />
          </Column>
        </Column>
        <NavItem
          isActive={router?.pathname === "/chat/notifications"}
          isCollapsed={isCollapsed}
          isLocked={false}
          onClick={() => router.push("/chat/notifications")}
          title="Notifications"
          IconComponent={
            <BellIcon
              width={24}
              color="white"
            />
          }
        />
        <UpgradeDialog
          isOpen={isUpgradeDialogOpen}
          onClose={() => setIsUpgradeDialogOpen(false)}
          planTitle="Pro"
          planName="pro"
        />
        <CollapsibleList
          isCollapsed={isCollapsed}
          defaultOpenState={!!isInDemo}
          containerStyle={{
            overflowX: "visible",
          }}
          parentContainerStyle={{ paddingX: PADDING_X_CONTAINER, flexDirection: "row-reverse" }}
          ParentComponent={
            <Row
              sx={{
                gap: 11,
                width: "100",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 0,
              }}
            >
              <Row
                width={ICON_BOX_WIDTH}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <ChatIcon
                  width={22}
                  height={22}
                />
              </Row>
              <Typography
                variant="appNavText"
                color={palette.brand.neutral.light}
              >
                AI Chat
              </Typography>
            </Row>
          }
          ListHeaderComponent={
            isCollapsed ? (
              <NavItem
                isActive={isChatPage}
                isCollapsed={isCollapsed}
                isLocked={false}
                IconComponent={
                  <ChatIcon
                    width={24}
                    height={24}
                  />
                }
                onClick={() => router.push("/chat")}
              />
            ) : (
              <NavItem
                isActive={false}
                isCollapsed={isCollapsed}
                isLocked={false}
                isIdented
                onClick={() => router.push("/chat")}
                title="New"
                IconComponent={
                  <Row
                    width={ICON_BOX_WIDTH}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <PlusSquareIcon
                      width={22}
                      height={22}
                    />
                  </Row>
                }
              />
            )
          }
          selectedComponentListStyle={{ paddingX: 15, display: isCollapsed ? "none" : "flex" }}
          componentListStyle={{
            listStyle: "none",
            flexDirection: "column",
            gap: 10,
            overflowX: "visible",
            paddingX: 15,
            paddingTop: 4,
            display: isCollapsed ? "none" : "flex",
          }}
          ComponentList={
            <>
              {conversations
                .filter((j) => j.platformId)
                .map((i, index) => (
                  <ChatHistoryItem
                    key={i.conversationId}
                    isActive={i.conversationId === activeConversationId}
                    onClick={() => router.push(`/chat/${i.conversationId}`)}
                    ref={
                      index === conversations.filter((j) => j.platformId).length - 1
                        ? ref
                        : undefined
                    }
                  >
                    <Typography
                      variant="appCaption"
                      color={palette.brand.neutral.light}
                      textAlign={"left"}
                      sx={{
                        textOverflow: "ellipsis",
                        display: "block",
                        zIndex: 1,
                      }}
                      noWrap
                    >
                      {i.title}
                    </Typography>
                  </ChatHistoryItem>
                ))}
              {isFetching && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <CircularProgress
                    size={21}
                    style={{ color: "white" }}
                  />
                </Box>
              )}
            </>
          }
        />
      </Box>
      {isCollapsed ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: 45,
            paddingX: PADDING_X_CONTAINER,
          }}
        >
          <Button
            sx={{
              display: "flex",
              padding: "5px !important",
              minWidth: "unset",
              background: palette.brand.neutral.light,
              justifyContent: "center",
              alignItems: "center",
              width: 45,
              height: 45,
              borderRadius: "50%",
              "&:hover": {
                background: palette.brand.neutral.light,
              },
            }}
            onClick={() => {
              Amplitude.logEvent("Pressed button to un-collapse the nav");
              setRecoil(collapseNavContainerState, false);
            }}
          >
            <ArrowWallIcon
              direction="left"
              width={27}
              height={27}
              color="black"
            />
          </Button>
        </Box>
      ) : !isCollapsed && (isTrialPeriod || hasTrialExpired) ? (
        <Box
          sx={{
            justifyContent: "flex-end",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: 45,
            paddingX: PADDING_X_CONTAINER,
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "flex",
            },
          }}
        >
          {/* <FreeTrialBlock
            variant="black"
            hasTrialExpired={hasTrialExpired}
            trialEnd={customer?.trialEnd}
          /> */}
        </Box>
      ) : (
        <></>
      )}
      <Divider color={DIVIDER_COLOR} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          paddingX: PADDING_X_CONTAINER,
        }}
      >
        <Button
          variant="typography"
          onClick={() => {
            Amplitude.logEvent("Pressed open settings modal");
            setIsSettingsOpen(true);
          }}
          sx={{
            width: "100%",
            gap: 10,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Avatar
              width={45}
              height={45}
              src={user?.avatarUrl ? createAvatarUrl(user.avatarUrl) : "/default-avatar.png"}
            />
          </Box>
          {isCollapsed ? (
            <></>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                variant="appBodyText"
                color={palette.brand.neutral.light}
                sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
              >
                {user?.firstName || "First"} {user?.lastName || "Last"}
              </Typography>
              <Typography
                variant="appCaption"
                color={palette.brand.neutral.gray}
                sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
              >
                {community?.name}
              </Typography>
            </Box>
          )}
          <ExpandIcon
            width={10}
            height={16}
            color="white"
          />
        </Button>
        <Modal
          open={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          sx={{ position: "relative" }}
        >
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                bottom: 100,
                width: 300,
                zIndex: 100,
                // maxWidth: 30,
                left: 15,
                background: "white",
                borderRadius: 12,
                padding: 15,
                gap: 10,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {communities.map((i) => (
                <Button
                  key={i.communityId}
                  variant="typography"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 10,
                    width: "100%",
                  }}
                >
                  <Avatar
                    width={45}
                    height={45}
                    src={i?.logoUrl ? createAvatarUrl(i.logoUrl) : "/default-source-icon.png"}
                  />
                  <Typography
                    variant="appCaption"
                    color={palette.brand.neutral.darkGray}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    {i.name}
                  </Typography>
                  <CheckboxCircleIcon
                    width={18}
                    height={18}
                  />
                </Button>
              ))}

              <Box
                sx={{
                  display: "flex",
                  borderTop: "2px solid",
                  borderColor: palette.brand.neutral.lightGray,
                  marginTop: 10,
                  width: "100%",
                  paddingTop: 15,
                }}
              >
                <Button
                  onClick={() => router.push("/chat/settings/profile")}
                  variant="typography"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 7,
                    width: "100%",
                    justifyContent: "flex-start",
                    paddingY: 5,
                  }}
                >
                  <Image
                    width={20}
                    height={20}
                    alt="Settings icon"
                    src={"/settings-wheel.svg"}
                    priority
                    style={{ objectFit: "contain", width: 20 }}
                    sizes={`(max-width: 768px) 40px, (max-width: 1200px) 40px, 40px`}
                  />
                  <Typography variant="appButtonText">Settings</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

const SourceItemContainer = styled("div")`
  justify-content: center;
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  width: 100%;

  gap: 10px;

  &:hover {
    background: ${palette.white.i10}50;
  }

  @media (max-width: 768px) {
    padding: 5px;
    flex-direction: column;
    justify-content: center;
    gap: unset;
  }
`;

const SourceItem = ({
  platformType,
  platformId,
  isActive,
  onClick,
  name,
  isCollapsed,
}: {
  platformType: PlatformEntity["platformType"];
  platformId: PlatformEntity["platformId"];
  isActive: boolean;
  onClick: (platformId: PlatformEntity["platformId"]) => void;
  name: PlatformEntity["name"];
  isCollapsed: boolean;
}) => {
  return (
    <SourceItemContainer>
      <Button
        onClick={() => onClick(platformId)}
        sx={{
          background: isActive ? palette.brand.neutral.light : "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          height: 36,
          width: 36,
          border: isActive ? "none" : `2px solid ${palette.brand.neutral.darkGray}`,
          minWidth: "unset",
          "&:hover": {
            background: isActive ? palette.brand.neutral.light : "transparent",
          },
        }}
      >
        <SourceIcon
          type={platformType}
          isActive={isActive}
          scheme="mono"
        />
      </Button>
      {isCollapsed ? (
        <></>
      ) : (
        <Box
          onClick={() => onClick(platformId)}
          sx={{ width: "100%", cursor: "pointer" }}
        >
          <Typography
            variant="appCaption"
            color={palette.brand.neutral.light}
          >
            {name}
          </Typography>
        </Box>
      )}
    </SourceItemContainer>
  );
};

const ChatHistoryItem = forwardRef(
  (
    {
      isActive,
      onClick,
      children,
    }: {
      isActive: boolean;
      onClick: () => void;
      children: JSX.Element | JSX.Element[];
    },
    ref: any
  ) => {
    return (
      <Button
        ref={ref}
        sx={{
          paddingY: "8px",
          paddingX: "0px",
          boxSizing: "border-box",
          width: "100%",
          position: "relative",
          background: isActive ? "#393939" : undefined,
          borderRadius: "6px",
          justifyContent: "flex-start !important",
          "&:before": {
            content: '""',
            position: "absolute",
            height: "100%",
            width: "105%",
            borderRadius: "6px",
            marginX: "-8px",
            zIndex: 0,
            background: isActive ? "#393939" : undefined,
          },
          "&:hover": {
            "&:before": {
              content: '""',
              position: "absolute",
              height: "100%",
              width: "105%",
              marginX: "-8px",
              borderRadius: "6px",
              zIndex: 0,
              background: "#393939",
            },
          },
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    );
  }
);

ChatHistoryItem.displayName = "ChatHistoryItem";
