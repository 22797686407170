const store = typeof window === "undefined" ? null : window.localStorage;

export const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: { setSelf: any; onSet: any }) => {
    if (store) {
      const savedValue = localStorage.getItem(key);
      if (savedValue != null && savedValue !== "undefined") {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue: any, _: any, isReset: any) => {
        isReset
          ? localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue));
      });
    }
  };

export const deleteKeyFromLocalStorage = (key: string) => {
  const savedValue = localStorage.getItem(key);
  if (savedValue) {
    localStorage.removeItem(key);
  }
};
