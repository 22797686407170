import { LogoImage } from "@assets/images/Logo";
import { Row } from "@components/common/Row";
import { Box, Button, Typography } from "@mui/material";
import { demoState, sessState } from "@recoil/atoms";
import palette from "@styles/palette";
import Image from "next/image";
import { getRecoil, setRecoil } from "recoil-nexus";
import { BoxCottonCandy } from "./common/BoxCottonCandy";

export const DEMO_CTA_HEIGHT = 56;

export default function DemoCTA() {
  const isDemo = getRecoil(demoState);
  const isLoggedInUser = !!getRecoil(sessState); // valid sessState indicates a user is logged in
  return isDemo ? (
    <BoxCottonCandy
      sx={{
        paddingX: 20,
        backgroundColor: palette.brand.neutral.dark,
        display: "flex",
        alignItems: "center",
        height: DEMO_CTA_HEIGHT,
        borderBottom: `1px solid ${palette.brand.neutral.dark}`,
        minHeight: "unset",
      }}
    >
      <Row
        alignItems={"center"}
        gap={10}
      >
        <LogoImage />
        <Typography variant="appBodyText">Astronaut Demo Account</Typography>
      </Row>
      <a
        href="https://astronaut.chat/use-cases?source=demo-cta"
        style={{
          marginLeft: "auto",
          marginRight: 18,
          textDecoration: "underline",
          textDecorationColor: "#222",
          fontWeight: 600,
          color: "#222",
        }}
      >
        Learn More
      </a>
      <Button
        href="https://astronaut.chat?source=demo-cta"
        variant="primary"
        className={"black"}
      >
        Get Access
      </Button>
      {isLoggedInUser && (
        <Button
          style={{
            position: "absolute",
            bottom: 20,
            right: 20,
            backgroundColor: palette.brand.pink.original,
            color: palette.brand.neutral.lightGray,
            borderRadius: 6,
            fontSize: 14,
            padding: "8px 12px",
            zIndex: 1,
          }}
          onClick={() => {
            setRecoil(demoState, false);
            window.location.reload();
          }}
        >
          Exit Demo
        </Button>
      )}
    </BoxCottonCandy>
  ) : null;
}
