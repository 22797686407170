import Image from "next/image";

export const DiscordIcon = ({
  width = 26,
  height = 20,
  color = "black",
}: {
  width?: number;
  height?: number;
  color?: "black" | "white" | "gray" | "dark" | "brand" | "brand-icon";
}) => (
  <Image
    alt="Discord logo"
    src={
      color === "white"
        ? "/discord-logo-white.svg"
        : color === "black"
        ? "/discord-logo-black.svg"
        : color === "dark"
        ? "/discord-dark.svg"
        : color === "gray"
        ? "/discord-gray.svg"
        : color === "brand"
        ? "/discord-logo.svg"
        : color === "brand-icon"
        ? "/discord-icon.svg"
        : "/discord-logo.svg"
    }
    width={width}
    height={height}
    priority
    style={{ objectFit: "contain", width }}
    sizes={`(max-width: 768px) ${width * 3}px, (max-width: 1200px) ${width * 3}px, ${width * 3}px`}
  />
);
