import publicApi from "@api/publicApi";
import { GetMeAPI } from "@astronautchat/common-backend";
import { DateRangeType } from "@components/DateRangeCalendar";
import { sortChannelsByFirstAlphanumeric } from "@lib/sortChannelsByFirstAlphanumeric";
import {
  activePlatformIdState,
  adminState,
  channelsState,
  communitiesState,
  communityState,
  conversationsState,
  customerState,
  customerUserState,
  dateRangeState,
  demoState,
  groupsState,
  memberGroupsState,
  moderationRolesState,
  onboardingPlatformState,
  platformGroupsState,
  platformListState,
  selectedMemberGroupState,
  selectedPlatformGroupState,
  sessState,
  tagsState,
  userState,
} from "@recoil/atoms";
import log from "@services/Log";
import { config } from "@utils/config";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { getRecoil, setRecoil } from "recoil-nexus";
import User from "./User";

const auth = {
  signOut: async () => {
    const isDemo = getRecoil(demoState);

    // For users viewing the demo, we want to redirect them to the website homepage so that they can go through the standard signup flows.
    const url = isDemo
      ? "https://astronaut.chat?source=demo_logout"
      : `${config.NEXT_PUBLIC_AUTH_API}/auth/logout?redirect=${config.NEXT_PUBLIC_ORIGIN}/signin`;

    unsetStateValues();
    window.location.href = url;
  },
  authorize: async (
    type: "chat" | "intro",
  ): Promise<{ status: "unauthorized" | "authorized"; user?: GetMeAPI }> => {
    try {
      const user = await User.getAuthorizedUser();
      log.identify(user);
      log.info("Retrieved User object", user);

      if (!user) {
        throw Error("User object was not retrieved.");
      }

      setChatStateValues(user);

      return { status: "authorized", user };
    } catch (error) {
      log.error(error);
      unsetStateValues();
      return { status: "unauthorized" };
    }
  },
  requestMagicLink: async function (email: string) {
    try {
      await publicApi.get(`/auth/requestMagicLink?email=${encodeURIComponent(email)}`);
    } catch (error: any) {
      if (error?.response?.data?.type === "NoUserFound") {
        throw Error("No user found with that email address.");
      } else {
        throw Error(error);
      }
    }
  },
};

const unsetStateValues = () => {
  log.info("Unsetting all of the state values");
  setRecoil(platformListState, []);
  setRecoil(customerUserState, undefined);
  setRecoil(customerState, undefined);
  setRecoil(communitiesState, []);
  setRecoil(communityState, undefined);
  setRecoil(activePlatformIdState, undefined);
  setRecoil(channelsState, []);
  setRecoil(userState, undefined);
  setRecoil(onboardingPlatformState, undefined);
  setRecoil(adminState, undefined);
  setRecoil(sessState, undefined);
  setRecoil(demoState, false);
  setRecoil(moderationRolesState, []);
  setRecoil(conversationsState, []);
  setRecoil(platformGroupsState, []);
  setRecoil(memberGroupsState, []);
  setRecoil(selectedPlatformGroupState, []);
  setRecoil(selectedMemberGroupState, []);
};

export const setChatStateValues = (user: GetMeAPI) => {
  log.info("Setting the various global recoil states for Chat");

  const customerUser = user.customerUsers[0];
  log.info("Extracting CustomerUser from the User object");
  setRecoil(customerUserState, {
    customerUserId: customerUser.customerUserId,
    customerId: customerUser.customerId,
    userId: customerUser.userId,
    role: customerUser.role,
  });

  log.info("Extracting Customer from the User object");
  const customer = customerUser.customer;
  setRecoil(customerState, {
    customerId: customer.customerId,
    name: customer.name,
    email: customer.email,
    address: customer?.address,
    hasIntegratedBot: customer.hasIntegratedBot,
    hasIntegratedSlackBot: customer.hasIntegratedSlackBot,
    stripeCustomerId: customer?.stripeCustomerId,
    phone: customer?.phone,
    subscriptionInterval: customer?.subscriptionInterval,
    subscriptionStatus: customer?.subscriptionStatus,
    amountPaid: customer?.amountPaid,
    currency: customer?.currency,
    planId: customer?.planId,
    productId: customer?.productId,
    subscriptionId: customer?.subscriptionId,
    subscriptionItemId: customer?.subscriptionItemId,
    paidAt: customer?.paidAt,
    trialStart: customer?.trialStart,
    trialEnd: customer?.trialEnd,
    currentPeriodStart: customer?.currentPeriodStart,
    currentPeriodEnd: customer?.currentPeriodEnd,
    cancelAtPeriodEnd: customer?.cancelAtPeriodEnd,
    hasTrialed: customer?.hasTrialed,
  });

  log.info("Extracting Communities from the User object");
  const communities = customerUser.customer.communities;
  setRecoil(
    communitiesState,
    communities.map((i) => ({
      communityId: i.communityId,
      customerId: i.customerId,
      name: i.name,
      createdAt: i.createdAt,
      updatedAt: i.updatedAt,
      deletedAt: i.deletedAt,
      logoUrl: i.logoUrl,
    })),
  );
  const activeCommunity = communities[0];
  setRecoil(communityState, {
    communityId: activeCommunity?.communityId,
    customerId: activeCommunity?.customerId,
    name: activeCommunity?.name,
    createdAt: activeCommunity?.createdAt,
    updatedAt: activeCommunity?.updatedAt,
    deletedAt: activeCommunity?.deletedAt,
    logoUrl: activeCommunity?.logoUrl,
  });

  log.info("Extracting platforms from the User object");
  const platforms = activeCommunity?.platforms || [];

  // This is a hack to filter out the platforms that havent completed integrating. Namely, this happens when a Telegram is in-between integration states. For example, a user auth's their Telegram with us but hasnt yet added us to a chat.
  const integratedPlatforms = platforms.filter(
    (i) =>
      (i?.platformDiscord || i?.platformTelegram || i?.platformSlack || i?.platformReddit) &&
      i.customerUserPlatform.filter((j) => j.customerUserId === customerUser.customerUserId)
        .length > 0,
  );
  if (integratedPlatforms.length !== 0) {
    const currentActivePlatform = getRecoil(activePlatformIdState);

    if (
      !currentActivePlatform ||
      // If active platformis not part of the currently integrated platforms, override the value.
      !integratedPlatforms.find((i) => i.platformId === currentActivePlatform)
    ) {
      /** @todo update to user the CustomerUserPlatform table */
      setRecoil(activePlatformIdState, integratedPlatforms[0]?.platformId);
    }
  }

  setRecoil(
    platformListState,
    integratedPlatforms.map((i) => ({
      platformId: i.platformId,
      communityId: i.communityId,
      platformType: i.platformType,
      name: i.name,
      hasCompletedIntegration: i.hasCompletedIntegration,
      hasCompletedOnboarding: i.hasCompletedOnboarding,
      description: i?.description,
      memberCount: i?.memberCount,
      isSyncing: i.isSyncing,
      isDisconnected: i.isDisconnected,
      platformDiscord: i?.platformDiscord || null,
      platformTelegram: i?.platformTelegram || null,
      platformSlack: i?.platformSlack || null,
      platformReddit: i?.platformReddit || null,
    })),
  );

  setRecoil(platformGroupsState, user.platformGroups);
  setRecoil(memberGroupsState, user.memberGroups);

  setRecoil(channelsState, sortChannelsByFirstAlphanumeric(user.channels || []));
  setRecoil(groupsState, sortChannelsByFirstAlphanumeric(user?.groups || []));
  setRecoil(conversationsState, user.conversations);

  setRecoil(tagsState, user?.tags || []);

  setRecoil(userState, {
    userId: user.userId,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    token: user.token,
    session: user.session,
    sub: user.sub,
    avatarUrl: user.avatarUrl,
    timezone: user.timezone,
    hasConfirmedCode: user.hasConfirmedCode,
    receiveAnnouncements: user.receiveAnnouncements,
    receiveReports: user.receiveReports,
    receiveTelegramAnnouncement: user.receiveTelegramAnnouncement,
    receiveSlackAnnouncement: user.receiveSlackAnnouncement,
    createdAt: user.createdAt,
    updatedAt: user.updatedAt,
    deletedAt: user.deletedAt,
  });

  setRecoil(dateRangeState, [
    startOfDay(subDays(new Date(), 7)),
    endOfDay(new Date()),
  ] as DateRangeType);
};

export default auth;
