import authApi from "@api/authApi";
import publicApi from "@api/publicApi";
import {
  CustomerUserInviteAPIResponse,
  GetMeAPI,
  TelegramAuthData,
  UserSignupProps,
} from "@astronautchat/common-backend";
import {
  CommunityEntity,
  ConversationEntity,
  CustomerEntity,
  CustomerRole,
  CustomerUserEntity,
  CustomerUserInviteEntity,
  PlatformDiscordEntity,
  PlatformEntity,
  UpdateUserEntity,
  UserEntity,
} from "@astronautchat/schema";
import { InviteResponseAPI, UserSignUpResponseAPI } from "@custom-types/user";
import { PatchInviteTokenAPI } from "@pages/api/user/invite/[customerUserInviteId]";
import { userState } from "@recoil/atoms";
import log from "@services/Log";
import axios from "axios";
import { setRecoil } from "recoil-nexus";
import Auth, { setChatStateValues } from "./Auth";

const user = {
  signUp: async (props: UserSignupProps): Promise<void> => {
    log.info("Sending request to sign up the user", props);
    await publicApi.post("/auth/signup", props);
    await Auth.requestMagicLink(props.email);
  },
  update: async (props: UpdateUserEntity): Promise<GetMeAPI> => {
    log.info("Updating the authorized CustomerUser", props);
    const res = await authApi.post(`/me`, props);

    log.info("Data returned from /me", { data: res.data });

    log.identify(res.data);

    setChatStateValues(res.data);
    setRecoil(userState, res.data);
    return res.data;
  },
  getAuthorizedUser: async (): Promise<GetMeAPI> => {
    log.info("Fetching the authorized User");
    const res = await authApi.get(`/me`);
    log.info("Data returned from /me", { data: res.data });
    return res.data;
  },
  /** Get one specific invite */
  getInvite: async ({
    token,
  }: {
    token: CustomerUserInviteEntity["customerUserInviteId"];
  }): Promise<InviteResponseAPI> => {
    log.info("Fetching the authorized User");
    const res = await axios.get(`/api/user/invite?token=${token}`);
    log.info("Data returned from /user/invite", { data: res.data });
    return res.data;
  },
  /** Get all of the invites for this user */
  getInvites: async (): Promise<CustomerUserInviteAPIResponse> => {
    log.info("Fetching the user's invites");
    const res = await authApi.get(`/me/invites`);
    return res.data;
  },
  /** Invite method for the Intros product */
  sendInvite: async ({
    email,
    firstName,
    lastName,
    customerId,
    role,
    inviterCustomerUserId,
  }: {
    email: UserEntity["email"];
    firstName: UserEntity["firstName"];
    lastName: UserEntity["lastName"];
    role: CustomerRole;
    customerId: CustomerEntity["customerId"];
    inviterCustomerUserId: CustomerUserInviteEntity["inviterCustomerUserId"];
  }): Promise<void> => {
    log.info("Sending request to invite the user", {
      email,
      firstName,
      lastName,
      customerId,
      role,
      inviterCustomerUserId,
    });

    await authApi.post(`/customer/${customerId}/invite`, {
      inviterCustomerUserId,
      firstName,
      lastName,
      email,
      role,
    });
  },
  /** Invite method for the Prompt product */
  sendPromptInvite: async ({
    email,
    firstName,
    lastName,
    customerId,
    role,
    inviterCustomerUserId,
  }: {
    email: UserEntity["email"];
    firstName: UserEntity["firstName"];
    lastName: UserEntity["lastName"];
    role: CustomerRole;
    customerId: CustomerEntity["customerId"];
    inviterCustomerUserId: CustomerUserInviteEntity["inviterCustomerUserId"];
  }): Promise<void> => {
    log.info("Sending request to invite the user", {
      email,
      firstName,
      lastName,
      customerId,
      role,
      inviterCustomerUserId,
    });

    await authApi.post(`/customer/${customerId}/prompt/invite`, {
      inviterCustomerUserId,
      firstName,
      lastName,
      email,
      role,
    });
  },
  updateInvite: async ({
    token,
  }: {
    token: CustomerUserInviteEntity["customerUserInviteId"];
  }): Promise<PatchInviteTokenAPI> => {
    log.info("Sending request to update invite", { token });
    const res = await axios.post(`/api/user/invite/${token}`, { isAccepted: true });
    return res.data;
  },
  syncDiscordMember: async ({
    access_token,
    token_type,
    communityId,
    guildId,
    platformId,
    customerUserId,
  }: {
    access_token: string;
    token_type: string;
    communityId: CommunityEntity["communityId"];
    guildId: PlatformDiscordEntity["guildId"];
    platformId: PlatformEntity["platformId"];
    customerUserId: CustomerUserEntity["customerUserId"];
  }): Promise<void> => {
    try {
      log.info("Syncing the Discord User to Astronaut", {
        access_token,
        token_type,
        communityId,
        guildId,
        platformId,
        customerUserId,
      });

      const res = await authApi.post(`/me/sync/discord`, {
        communityId,
        guildId,
        platformId,
        token_type,
        access_token,
        customerUserId,
      });

      const data: GetMeAPI = res.data;

      log.info("Data returned from /me/sync/discord", { data });

      log.identify(data);

      setChatStateValues(data);
    } catch (error) {
      log.error(error);
    }
  },
  authTelegram: async ({
    id,
    first_name,
    last_name,
    username,
    photo_url,
    auth_date,
    hash,
  }: TelegramAuthData): Promise<GetMeAPI> => {
    log.info("Sending request to authorize the Telegram user", {
      id,
      first_name,
      last_name,
      username,
      photo_url,
      auth_date,
      hash,
    });
    const res = await authApi.post(`/me/auth/telegram`, {
      id,
      first_name,
      last_name,
      username,
      photo_url,
      auth_date,
      hash,
    });

    return res.data;
  },
  getConversations: async ({
    take,
    page,
  }: {
    take: number;
    page: number;
  }): Promise<ConversationEntity[]> => {
    log.info("Fetching the user's conversations", { take, page });
    const res = await authApi.get(`/me/conversations`, { params: { take, page } });
    return res.data;
  },
  submitFeedback: async ({ feedback }: { feedback: string }) => {
    log.info("Submitting the user's feedback", { feedback });
    await authApi.post(`/me/feedback`, { feedback });
    return;
  },
};

export default user;
