import { PaletteOptions } from "@mui/material/styles/createPalette";

const palette: PaletteOptions = {
  text: {
    primary: "#000",
    secondary: "white",
    // disabled: ''
  },

  background: {
    default: "#fff",
    // paper: ''
  },

  primary: {
    main: "#136EED",
    light: "#CDF4FC",
    dark: "#7350FF",
    // contrastText: ''
  },

  secondary: {
    main: "#fff",
    // light: '',
    // dark: '',
    // contrastText: ''
  },

  info: {
    main: "#1A73E8",
  },

  success: {
    main: "#4CAF50",
  },

  warning: {
    main: "#fb8c00",
    dark: "#FFBB00",
    light: "#FFF0CF",
  },

  error: {
    main: "#F44335",
    light: "#FFEFEB",
  },

  black: {
    i1: "#666666",
    i2: "#222222",
    i3: "#111111",
    i10: "#000",
  },

  white: {
    i1: "rgba(244,244,244,0.05)",
    i10: "#fff",
  },

  brand: {
    error: {
      default: "#F23333",
    },
    pink2: {
      shadow: "#C70C7C",
      default: "#F233A6",
      hover: "#FC7FCA",
      disabled: "#FDB4E0",
      bg: "#FFEBF7",
    },
    pink: {
      i1: "#FEEFF8",
      i2: "#F9BDE1",
      i3: "#F58CCB",
      original: "#F15BB5",
      i4: "#EB1E99",
      i5: "#B71074",
      i6: "#7A0B4D",
    },
    yellow: {
      i1: "#FFFCEC",
      i2: "#FFF4B2",
      i3: "#FEEC78",
      original: "#FEE43E",
      i4: "#FCDA01",
      i5: "#BDA401",
      i6: "#7E6D01",
      website: "#FFF3BE",
    },
    purple: {
      i1: "#F5EFFC",
      i2: "#D7BEF4",
      i3: "#B88DED",
      original: "#9A5DE5",
      i4: "#7725DC",
      i5: "#591BA6",
      i6: "#3C126F",
    },
    green: {
      i1: "#E4FFEB",
      i2: "#95FFB1",
      i3: "#45FF77",
      original: "#00F541",
      i4: "#00C434",
      i5: "#009327",
      i6: "#00621A",
    },
    offWhite: "rgba(244, 244, 244, 0.9)",
    fadedBlack: "rgba(0, 0,0, 0.1)",
    red: {
      i1: "#FFE7E5",
      i2: "#FF9E97",
      i3: "#FF5549",
      original: "#FA1100",
      i4: "#C80D00",
      i5: "#960A00",
      i6: "#640700",
    },
    grey: {
      i1: "#FFFFFF",
      i2: "#EEEEEE",
      i3: "#CCCCCC",
      original: "#999999",
      i4: "#666666",
      i5: "#222222",
      i6: "#111111",
    },
    neutral: {
      light: "#F9F9F6",
      lightGray: "#F3F3EE",
      gray: "#B5B5AF",
      darkGray: "#767671",
      dark: "#222222",
    },
    info: {
      shadow: "#0C37C7",
      default: "#3360F2",
      hover: "#7F9CFC",
      disabled: "#B4C5FD",
      bg: "#EBEFFF",
    },
  },

  external: {
    discord: {
      primary: "#5965F1",
    },
    google: {
      primary: "blue",
      secondary: "#E5E7FF",
    },
  },

  border: {
    dark: "#130D2A",
  },

  navigation: {
    missions: {
      face: {
        active: "white",
        inactive: "#00FFFFFF",
      },
      body: {
        active: "black",
        inactive: "#666666",
      },
      glare: {
        active: "black",
        inactive: "#666666",
      },
    },
  },

  analytics: {
    positive: "#34A853",
    negative: "red",
    line: "#EB7D78",
  },
};

export default palette;
