import { UserEntity } from "@astronautchat/schema";
import { communityState } from "@recoil/atoms";
import { getRecoil } from "recoil-nexus";

/**
 *
 * @param user
 * @param useOriginRoute the route which the user originally visited
 * @returns Path (or no path) to be directed to
 */
export function decideStartPage(user: UserEntity, useOriginRoute: boolean): string | undefined {
  const community = getRecoil(communityState);

  if (!user?.firstName && !user?.lastName) {
    // User has not entered their name.
    return "/o/profile";
  } else if (!community || !community?.name) {
    // User hasn't created their community yet.
    return "/o/organization";
  } else {
    if (useOriginRoute) {
      // User has completed onboarding and will be directed to their origin route.
      return;
    } else {
      // User has completed onboarding.
      return "/chat/reports";
    }
  }
}
